<template>
  <el-dialog
    class="rate-us-dialog"
    :model-value="props.isDialogVisible"
    show-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="emit('close')"
    title="We're curious, are you loving this guide?"
  >
    <div v-show="isThankYouScreenVisible" class="button-row">
      <p>Thank you!</p>
      <el-button @click="emit('close')" type="primary">CLOSE</el-button>
    </div>

    <div v-show="!isThankYouScreenVisible" class="icons-row">
      <div @click="rateGuide('like')">
        <i class="icon-Like"></i>
      </div>

      <div @click="rateGuide('dislike')">
        <i class="icon-Dislike"></i>
      </div>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { TS_IGNORE_RATE_US_KEY } from '@/utils/consts'
import api from '@/api'
import { useHelperStore } from '@/pinia/helper.store'

const helperStore = useHelperStore()
const props = defineProps<{ isDialogVisible: boolean }>()
const emit = defineEmits(['close', 'remove-listener'])
const isThankYouScreenVisible = ref(false)
async function rateGuide(result: 'like' | 'dislike') {
  isThankYouScreenVisible.value = true

  await api.submitFeedback(result === 'like')
  emit('remove-listener')

  localStorage.setItem(TS_IGNORE_RATE_US_KEY + helperStore.guideToken, 'true') // to ignore this in the future
}
</script>

<style lang="scss">
.rate-us-dialog {
  width: 70%;
  margin-top: 30vh;
  border-radius: 8px;

  .el-dialog__headerbtn {
    width: 25px;
    height: 25px;
    right: 6px;

    i,
    svg {
      width: inherit;
      height: inherit;
      font-weight: bold;
    }
  }

  .el-dialog__header {
    margin-right: 0;
    text-align: center;
    padding-top: 30px;

    .el-dialog__title {
      font-size: 1.25rem;
    }
  }

  .el-dialog__body {
    padding: 15px;

    .icons-row {
      display: flex;
      justify-content: space-around;
      align-items: center;

      i {
        font-weight: bold;
        font-size: 2.5rem;
        padding: 10px;
      }

      .icon-Like {
        color: #2bb449;
      }

      .icon-Dislike {
        color: #ff7f58;
      }
    }

    .button-row {
      display: flex;
      flex-direction: column;
      font-family: v-bind('helperStore.headerFont');

      p {
        text-align: center;
        font-size: 1.25rem;
      }

      .el-button {
        font-size: 1rem;
        line-height: 1rem;
        padding: 15px;
      }
    }
  }
}
</style>
