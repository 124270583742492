<template>
  <div v-if="!loading" class="main-layout" :style="{ fontFamily: helperStore.textFont }">
    <router-view v-slot="{ Component }">
      <keep-alive :include="cachedViews">
        <component :is="Component" :key="route.name" />
      </keep-alive>
    </router-view>

    <layout-footer v-if="showFooter" />
  </div>
</template>

<script lang="ts" setup>
import LayoutFooter from '@/components/LayoutFooter.vue'
import { computed, onBeforeMount, onBeforeUnmount } from 'vue'
import RouteNamesEnum from '@/router/route-names'
import { useRoute, useRouter } from 'vue-router'
import registerServiceWorker from '@/register-sw'
import { showDevMessage } from '@/utils/helpers'
import { useMainStore } from '@/pinia/main.store'
import { useHelperStore } from '@/pinia/helper.store'

const mainStore = useMainStore()
const helperStore = useHelperStore()

const route = useRoute()
const router = useRouter()

let interval = null
const cachedViews = ['InfoGuideView', 'MapView', 'HomeView', 'SearchView']

const theme = computed(() => helperStore.styleData)
const loading = computed(() => helperStore.isLoadingViewVisible)
const showMapTab = computed(() => !mainStore.propertyInfo.hide_map_tab)
const showFooter = computed(() => route.name !== RouteNamesEnum.ERROR)

onBeforeMount(async () => {
  const guideToken = route.params.guideToken as string

  if (!guideToken) {
    return
  }

  helperStore.guideToken = guideToken

  if (!showMapTab.value) {
    router.removeRoute(RouteNamesEnum.MAP)
  }

  try {
    appendManifest(guideToken)
    registerServiceWorker(fetchCore)
  } catch (e) {
    showDevMessage('error', 'Service worked or manifest has failed to register.')
    console.error(e)
  }

  await helperStore.checkGuideAvailability()

  interval = setInterval(
    () => {
      if (navigator.onLine) {
        helperStore.checkGuideAvailability()
      }
    },
    1000 * 60 * 60
  ) // 1 hour
})

onBeforeUnmount(() => {
  clearInterval(interval)
})

async function fetchCore() {
  try {
    helperStore.isLoadingViewVisible = true
    await mainStore.fetchMainData()

    setDomStylingElements()
    localStorage.setItem('tsFetched', Date.now().toString())

    showDevMessage('success', 'Fetch core successful')
  } catch (e) {
    showDevMessage('error', 'Failed to fetch API data: ' + e)
  } finally {
    helperStore.isLoadingViewVisible = false
  }
}

function appendManifest(guideToken: string) {
  const link = document.createElement('link')
  link.rel = 'manifest'
  link.href = `${window.location.origin}/v2api/wb/manifest/json/${guideToken}/`
  document.querySelector('head').appendChild(link)
}

function setDomStylingElements() {
  // element primary color
  document.documentElement.style.setProperty(
    '--el-color-primary',
    helperStore.styleData.accentColor
  )
  document.body.style.backgroundColor = theme.value.accentColor
  document
    .querySelector('meta[name="theme-color"]')
    .setAttribute('content', theme.value.accentColor)
}
</script>

<style lang="scss">
.main-layout {
  position: relative;
  width: 100vw;
  height: calc(100% - #{$footer-height});
  background-color: #f3f3f3;
}

.layout-footer {
  bottom: 0;
  position: fixed;
  background-color: $primary;
  width: 100%;
  height: calc(#{$footer-height} + 1px); // older Android border fix
  overflow: hidden;
  z-index: 500;
  box-shadow: $info-footer-shadow;
  transition: all 0.3s ease-in-out;
}

.layout-nav {
  position: relative;
  display: flex;
  align-items: center;
  height: $footer-height;

  &__item {
    flex: 1 1 0;
    height: 100%;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    letter-spacing: 0.125rem;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;

    i {
      font-size: rem(18);
      padding-bottom: 5px;
    }

    @include mediaDesktop {
      &:hover {
        background-color: var(--color-hover);
        color: var(--color-hover-text) !important;
      }
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
