<template>
  <div v-if="props.isLoading" class="loader-wrapper">
    <div class="loader">
      <div class="loader-icon__container">
        <transition name="fade">
          <img
            :key="data.fallBackIconUrl"
            :src="data.fallBackIconUrl"
            alt="icon"
            class="loader-icon fb"
          />
        </transition>
      </div>

      <transition name="fade">
        <div v-if="data.isFirstTextVisible" class="loader-text typing">
          Your guidebook is loading
        </div>
      </transition>

      <transition name="fade">
        <div v-if="data.isSecondTextVisible" class="loader-text typing">
          Wow, lots of great content here! Nearly ready.
        </div>
      </transition>

      <el-row
        type="flex"
        align="middle"
        v-show="data.isLoadingMoreThanTenSeconds && data.isIOS"
        class="reload-block"
      >
        <p class="loader-text">Loading stuck?</p>
        <p class="loader-text">
          Try using the <img :src="safariIconUrl" class="safari-icon" alt="Safari Icon" /> icon at
          the bottom right.
        </p>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive } from 'vue'
import 'detect-browser'
import { detectOS } from 'detect-browser'
import { isMobile } from '@/utils/helpers'
import { useHelperStore } from '@/pinia/helper.store'

const helperStore = useHelperStore()
const props = defineProps<{ isLoading: boolean }>()
const STATIC_SVG_PATH = `${window.location.origin}/static/svg`
const fallbackIcons = [
  `${STATIC_SVG_PATH}/load-pin-1.svg`,
  `${STATIC_SVG_PATH}/load-pin-2.svg`,
  `${STATIC_SVG_PATH}/load-pin-3.svg`,
  `${STATIC_SVG_PATH}/load-pin-4.svg`,
  `${STATIC_SVG_PATH}/load-pin-6.svg`
]
const safariIconUrl = `${STATIC_SVG_PATH}/safari-icon.svg`
let fallbackIconIndex = 1

const data = reactive({
  fallBackIconUrl: fallbackIcons[0],
  isFirstTextVisible: false,
  isSecondTextVisible: false,
  isLoadingMoreThanTenSeconds: false,
  isIOS: detectOS(navigator.userAgent) === 'iOS'
})

onMounted(() => {
  changeText()
  changeFallBackIcon()

  if (isMobile.value) {
    setTimeout(() => {
      data.isLoadingMoreThanTenSeconds = true
    }, 10000)
  }
})

function changeFallBackIcon() {
  setTimeout(() => {
    if (fallbackIconIndex !== fallbackIcons.length) {
      data.fallBackIconUrl = fallbackIcons[fallbackIconIndex]
      fallbackIconIndex += 1
    } else {
      fallbackIconIndex = 0
    }
    changeFallBackIcon()
  }, 1800)
}

function changeText() {
  setTimeout(() => {
    data.isFirstTextVisible = true
  }, 1000)
  setTimeout(() => {
    data.isFirstTextVisible = false
    data.isSecondTextVisible = true
  }, 5000)
}
</script>

<style lang="scss" scoped>
.loader {
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  background-color: #fff;

  &-cards {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }

  &-wrapper {
    position: relative;
  }

  &-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.fb {
      animation: none;
    }

    &.ping {
      width: 100vw;
      height: 100%;
      animation: ping 4.2s ease-in-out infinite both;
      background-color: #3c3c3c;
    }

    &__container {
      position: fixed;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
    }
  }

  &-text {
    font-family: v-bind('helperStore.headerFont');
    font-size: rem(22);
    font-weight: bold;
    line-height: 1.75;
    border-radius: $border-radius;
    letter-spacing: 0.02857em;
    width: 90%;
    text-align: center;
  }

  .el-loading-spinner .path {
    stroke: #3c3c3c;
  }

  .reload-block {
    position: absolute;
    bottom: 25%;
    font-weight: bold;

    p {
      margin: 0;
      font-size: 1.125rem;
      width: 100%;
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes ping {
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0.2;
  }
  80% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(2.2);
    opacity: 0;
  }
}

.icon-cards {
  position: relative;
  width: 48px;
  height: 48px;
  max-width: 380px;
  max-height: 250px;
  margin: 0;
  color: white;
  perspective: 1000px;
  transform-origin: center;

  // This is the element that rotates with the animation

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(0) translateZ(-24px);
    animation: carousel 10s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards;

    &.step-animation {
      animation: carousel 8s infinite steps(1) forwards;
    }
  }

  // Individual cards

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    max-width: 380px;
    max-height: 250px;
    border-radius: $border-radius;
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-icon {
      width: 48px;
      height: 48px;
      object-fit: contain;
    }

    &:nth-child(1) {
      transform: rotateY(0) translateZ(28px);
    }

    &:nth-child(2) {
      transform: rotateY(120deg) translateZ(28px);
    }

    &:nth-child(3) {
      transform: rotateY(240deg) translateZ(28px);
    }
  }
}

.safari-icon {
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
}

// Carousel animation
@keyframes carousel {
  0%,
  17.5% {
    transform: translateZ(-28px) rotateY(0);
  }
  27.5%,
  45% {
    transform: translateZ(-28px) rotateY(-120deg);
  }
  55%,
  72.5% {
    transform: translateZ(-28px) rotateY(-240deg);
  }
  82.5%,
  100% {
    transform: translateZ(-28px) rotateY(-360deg);
  }
}
</style>
