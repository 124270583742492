import { createRouter, createWebHistory } from 'vue-router'
import RouteNamesEnum from '@/router/route-names'
import MainLayout from '@/views/MainLayout.vue'
import FontTestView from '@/views/FontTestView.vue'
import { useHelperStore } from '@/pinia/helper.store'

const InfoGuideView = () => import('@/views/InfoGuideView.vue')
const HomeView = () => import('@/views/HomeView.vue')
const MapView = () => import('@/views/MapView.vue')
const SearchView = () => import('@/views/SearchView.vue')
const ErrorView = () => import('@/views/ErrorView.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/guest/',
      component: MainLayout,
      children: [
        {
          path: ':guideToken/error/',
          name: RouteNamesEnum.ERROR,
          component: ErrorView
        },
        {
          path: ':guideToken/',
          name: RouteNamesEnum.HOME,
          component: HomeView
        },
        {
          path: ':guideToken/info/:subcategoryId?',
          name: RouteNamesEnum.INFO,
          component: InfoGuideView
        },
        {
          path: ':guideToken/guide/:subcategoryId?',
          name: RouteNamesEnum.GUIDE,
          component: InfoGuideView
        },
        {
          path: ':guideToken/map/',
          name: RouteNamesEnum.MAP,
          component: MapView
        },
        {
          path: ':guideToken/search/',
          name: RouteNamesEnum.SEARCH,
          component: SearchView
        }
      ]
    },
    {
      path: '/guest/fonts/',
      name: 'Fonts',
      component: FontTestView
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: ':guideToken/error/'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.name && to.name === RouteNamesEnum.ERROR) {
    const helperStore = useHelperStore()
    helperStore.isLoadingViewVisible = false
    next()

    return
  }

  next()
})

export default router
