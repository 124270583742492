<template>
  <div id="app" :style="{ fontFamily: helperStore.textFont }">
    <loading-view :is-loading="isLoading" />
    <div class="loading-container">
      <div v-for="n in 8" :key="n" :class="['bar', 'bar' + n]"></div>
    </div>

    <template v-if="isInstallMessageVisible">
      <!-- iOS PWA message -->
      <transition v-if="isMobileSafari" name="fade">
        <div class="prompt-ios" :class="{ 'prompt-ios--ipad': isIpad }">
          <div class="prompt-info">
            <div class="prompt-description">
              <p>
                To add a shortcut to your home screen, hit the
                <i class="icon-Share-1" />
                icon and tap "Add to Home Screen”.
                <br />
                Option not showing for you? Copy and paste this link into Safari and try again.
              </p>
            </div>

            <i class="icon-Cross" @click="data.showInstallMessage = false" />
          </div>
        </div>
      </transition>

      <!-- Android PWA message -->
      <transition v-if="isMobileChromeAndroid" name="fade">
        <div class="prompt" :style="{ backgroundColor: theme.backgroundColor }">
          <i class="icon-Cross" @click="data.showInstallMessage = false" />
          <div class="prompt-info">
            <div class="prompt-description">
              <span> Add to Home Screen for faster access, even when offline. </span>
            </div>
          </div>

          <a class="prompt-btn" :style="androidInstallerStyle" @click="addAndroidPwa"> Add </a>
        </div>
      </transition>
    </template>

    <rate-us-dialog
      :is-dialog-visible="data.isRateDialogVisible"
      @close="data.isRateDialogVisible = false"
      @remove-listener="removeListener"
    />

    <router-view v-if="data.isPreviewDesktopMode" />
  </div>
</template>

<script lang="ts" setup>
import LoadingView from '@/views/LoadingView.vue'
import { detect } from 'detect-browser'
import { ClientJS } from 'clientjs'
import { isMobile, isPreviewMode, sendPlausibleEvent } from '@/utils/helpers'
import {
  PlausibleEvents,
  PlausibleProperties,
  TOUCH_INTERACTIONS_TO_SHOW_FEEDBACK_DIALOG,
  TS_IGNORE_RATE_US_KEY
} from '@/utils/consts'
import { computed, onMounted, reactive, watch } from 'vue'
import { ElMessage, ElNotification } from 'element-plus'
import { useRoute } from 'vue-router'
import { initPullToRefresh } from '@/utils/pull-to-refresh'
import RateUsDialog from '@/components/RateUsDialog.vue'
import { useHelperStore } from '@/pinia/helper.store'
import { useMainStore } from '@/pinia/main.store'

const helperStore = useHelperStore()
const mainStore = useMainStore()
const route = useRoute()

let androidInstallPrompt = null
const browserInfo = detect(navigator.userAgent)
const isPwa = window.matchMedia('(display-mode: standalone)').matches
const isIpad = browserInfo.name === 'safari' && browserInfo.os === 'Mac OS' // same for Mac
const isAppleDevice = ['iOS', 'Mac OS'].includes(browserInfo.os)
const isMobileSafari = isAppleDevice && ['ios', 'safari'].includes(browserInfo.name)
const isMobileChromeAndroid = browserInfo.os === 'Android OS' && browserInfo.name === 'chrome'

const data = reactive({
  showInstallMessage: false,
  isPreviewDesktopMode: true,
  isPreviewMode: false,
  isRateDialogVisible: false,
  iframeSrc: '',
  touchInteractions: 0
})
const isLoading = computed(() => helperStore.isLoadingViewVisible)
const theme = computed(() => helperStore.styleData)
const androidInstallerStyle = computed(() => ({
  backgroundColor: theme.value.accentColor,
  color: theme.value.overlayColor === '#FFFFFF' ? '#000' : '#fff'
}))
const isInstallMessageVisible = computed(() => {
  return (
    'serviceWorker' in navigator &&
    !isLoading.value &&
    !isPwa &&
    isMobile.value &&
    data.showInstallMessage &&
    !isPreviewMode()
  )
})
const allowGuestFeedback = computed(() => helperStore.styleData?.allowGuestFeedback)

// mounted will not work due to API call
const unwatchGuestFeedbackResult = watch(allowGuestFeedback, (allow) => {
  if (allow) {
    initRateUsCounter()
    unwatchGuestFeedbackResult() // only once
  }
})

// this watcher is needed to get propertyInfo to send with the event
const unwatchCoreFetched = watch(
  () => mainStore.propertyInfo.id,
  (destinationId) => {
    if (destinationId) {
      const client = new ClientJS()
      sendPlausibleEvent(PlausibleEvents.GUIDE_LOAD, {
        [PlausibleProperties.FINGERPRINT]: client.getFingerprint()
      })

      if (isPwa) {
        sendPlausibleEvent(PlausibleEvents.PWA_LAUNCH)
      }

      unwatchCoreFetched()
    }
  }
)

function setHeightVariable() {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

async function checkOfflineCapability() {
  const iosNotSafari = browserInfo.os === 'iOS' && browserInfo.name !== 'ios'
  const androidNotChrome = browserInfo.os === 'Android OS' && browserInfo.name !== 'chrome'
  const recommendedBrowser = iosNotSafari ? 'Safari' : 'Chrome'

  // PWA supports only Safari on iOS and Chrome on Android
  if (isMobile.value && (iosNotSafari || androidNotChrome)) {
    const el = ElNotification({
      type: 'info',
      showClose: true,
      dangerouslyUseHTMLString: true,
      message: `Your browser doesn't fully support offline capability.
      For the best experience we recommend using ${recommendedBrowser}. <br>
      <a href="#" style="display: flex; justify-content: center">Tap here to copy the guide link</a>`,
      duration: 0,
      onClick() {
        navigator.clipboard.writeText(location.href)
        ElMessage.success('Link copied to clipboard!')
        el.close()
      }
    })
  }
}

async function addAndroidPwa() {
  androidInstallPrompt.prompt()
  data.showInstallMessage = false
  androidInstallPrompt = null
}

function changePreview(isPreviewDesktopMode: boolean) {
  data.isPreviewDesktopMode = isPreviewDesktopMode
  document.body.style.backgroundColor = isPreviewDesktopMode ? theme.value.accentColor : 'white'
}

onMounted(() => {
  setHeightVariable()
  checkOfflineCapability()

  if (isMobileSafari) {
    data.showInstallMessage = true
  }

  // wait for init and check preview
  setTimeout(() => {
    if (route.query.preview) {
      data.iframeSrc = window.location.origin + '/guest/' + helperStore.guideToken
      data.isPreviewMode = true
    }
  }, 1000)

  // prevent default and assign custom install prompt for Android
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault()
    androidInstallPrompt = e
    data.showInstallMessage = true
  })

  // needed to handle iOS devices properly on resize
  window.addEventListener('resize', () => {
    setTimeout(() => {
      setHeightVariable()
    }, 500)
  })

  // Reload the PWA when switched to active. Debounce needed because it fires twice for some reason
  window.addEventListener('visibilitychange', visibilityChangeEventListener)

  if (isAppleDevice) {
    // font-smoothing for Mac Chrome, check index.scss
    document.body.classList.add('apple-device')
  }

  if (isPwa && isMobileSafari) {
    initPullToRefresh()
  }
})

function visibilityChangeEventListener() {
  if (document.visibilityState === 'visible') {
    const lastFetched = +localStorage.getItem('tsFetched')
    const haveTwelveHoursPassed = lastFetched && Date.now() - lastFetched >= 12 * 60 * 60 * 1000
    const client = new ClientJS()

    sendPlausibleEvent(PlausibleEvents.GUIDE_FOCUS, {
      [PlausibleProperties.FINGERPRINT]: client.getFingerprint()
    })

    if (haveTwelveHoursPassed) {
      window.location.reload()
    }
  }
}

function initRateUsCounter() {
  const hasRatingBeenSubmitted = localStorage.getItem(
    TS_IGNORE_RATE_US_KEY + helperStore.guideToken
  )

  if (hasRatingBeenSubmitted) {
    return
  }

  window.addEventListener('touchend', touchendFunction)
}

function touchendFunction() {
  data.touchInteractions += 1

  if (data.touchInteractions === TOUCH_INTERACTIONS_TO_SHOW_FEEDBACK_DIALOG) {
    data.isRateDialogVisible = true
    data.touchInteractions = 0
  }
}

function removeListener() {
  window.removeEventListener('touchend', touchendFunction)
}
</script>

<style lang="scss">
@import '/src/styles/index.scss';

.skiptranslate iframe {
  display: none !important;
}

#goog-gt-tt.skiptranslate {
  display: none !important;
}

.goog-te-gadget.skiptranslate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  & > div,
  .goog-te-combo {
    cursor: pointer;
    height: 100%;
    min-height: 100%;
    max-width: 100%;
  }
}

.add-desktop {
  position: fixed;
  top: 5rem;
  right: 5rem;
  width: 200px;
  height: 40px;
  z-index: 20;
  background-color: white;
}

.loader {
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  .el-loading-spinner .path {
    stroke: #3c3c3c;
  }
}

.prompt {
  background-color: black;
  display: flex;
  align-items: center;
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  padding: 20px;
  width: 100%;

  i {
    font-size: rem(22);
    margin-right: rem(22);
  }

  &-title {
    font-weight: bold;
    font-size: rem(13);
    margin-bottom: 8px;
  }

  &-description {
    font-size: rem(16);

    img {
      max-height: 1rem;
    }

    &__ios {
      fill: transparent;
      stroke: currentColor;
      height: 16px;
    }
  }

  &-btn {
    background-color: white;
    color: white;
    padding: 10px 32px;
    margin-left: rem(22);
    cursor: pointer;
    border-radius: $border-radius;
  }

  &-ios {
    background-color: white;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    bottom: 15px;
    z-index: 999999999;
    text-align: center;
    line-height: rem(16);
    padding: $padding $padding 0;
    box-shadow: 0 10px 51px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius;

    &--ipad {
      bottom: inherit;
      top: 15px;
      left: initial;
      right: 20px;
      transform: none;
      width: 50%;

      &:after {
        left: 72.5% !important;
        top: -15px !important;
        border-top: none !important;
        border-bottom: solid 15px white !important;
      }
    }

    p {
      margin-top: 0;
    }

    i.el-icon-close {
      font-size: rem(22);
      position: absolute;
      right: 0;
      top: 0;
      background-color: white;
      transform: translate(50%, -50%);
      border-radius: $border-radius;
      box-shadow:
        0 10px 20px rgba(0, 0, 0, 0.19),
        0 6px 6px rgba(0, 0, 0, 0.23);
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -15px;
      width: 0;
      height: 0;
      border-top: solid 15px white;
      border-left: solid 15px transparent;
      border-right: solid 15px transparent;
    }

    .icon-Cross {
      font-size: 1.375rem;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #fff;
      transform: translate(50%, -50%);
      border-radius: $border-radius;
      box-shadow:
        0 10px 20px rgb(0 0 0 / 19%),
        0 6px 6px rgb(0 0 0 / 23%);
    }
  }
}

.preview-box {
  position: fixed;
  left: 100px;
  top: 100px;
  z-index: 9999;
  background: white;
}

iframe.mobile-view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  width: 25vw;
  border: none;
}

.loading-container {
  height: 80px;
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  &.visible {
    position: relative;
    top: 0;
  }

  .bar {
    width: 6px;
    height: 16px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    opacity: 0;
    animation: fade-spinner 1s linear infinite;
    border-radius: 50px;
  }

  $translateY: -100%;

  .bar1 {
    transform: rotate(0deg) translateY($translateY);
    animation-delay: 0s;
  }

  .bar2 {
    transform: rotate(45deg) translateY($translateY);
    animation-delay: -0.875s;
  }

  .bar3 {
    transform: rotate(90deg) translateY($translateY);
    animation-delay: -0.75s;
  }

  .bar4 {
    transform: rotate(135deg) translateY($translateY);
    animation-delay: -0.625s;
  }

  .bar5 {
    transform: rotate(180deg) translateY($translateY);
    animation-delay: -0.5s;
  }

  .bar6 {
    transform: rotate(225deg) translateY($translateY);
    animation-delay: -0.375s;
  }

  .bar7 {
    transform: rotate(270deg) translateY($translateY);
    animation-delay: -0.25s;
  }

  .bar8 {
    transform: rotate(315deg) translateY($translateY);
    animation-delay: -0.125s;
  }
}

@-webkit-keyframes fade-spinner {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
</style>
