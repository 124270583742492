export enum LanguageCodesEnum {
  EN = 'en',
  FR = 'fr',
  DE = 'de',
  ES = 'es',
  FI = 'fi',
  PT = 'pt',
  IT = 'it',
  NL = 'nl',
  NO = 'no',
  RU = 'ru',
  EL = 'el',
  JA = 'ja',
  CY = 'cy',
  RO = 'ro',
  IND = 'ind',
  BG = 'bg',
  ZH = 'zh'
}

export enum LanguageModesEnum {
  MULTI = 'multi_language',
  GOOGLE = 'google_translate',
  DISABLED = 'disabled'
}
