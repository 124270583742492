import axios, { AxiosError, AxiosResponse } from 'axios'
import router from '@/router/index'
import RouteNamesEnum from '@/router/route-names'
import { showDevMessage } from '@/utils/helpers'
import { ElNotification } from 'element-plus'
import { useHelperStore } from '@/pinia/helper.store'

const request = axios.create({
  baseURL: window.location.origin.includes('localhost')
    ? import.meta.env.VITE_API_URL || 'https://guide-develop.touchstay.com/v2api'
    : window.location.origin + '/v2api'
})

request.interceptors.response.use(onSuccess, onError)

function onSuccess(response: AxiosResponse) {
  return response.data
}
async function onError(error: AxiosError) {
  const data = error?.response?.data as { code?: string; message?: string; detail?: string }
  const helperStore = useHelperStore()

  if (!data) {
    return Promise.reject()
  }

  if (data.message) {
    await router.push({ name: RouteNamesEnum.ERROR, query: { errorCode: data.code } })

    helperStore.errorMessage = data.message
    showDevMessage('error', 'Request error: ' + data.message)
  }

  if (data.detail) {
    ElNotification.error({
      message: data.detail,
      duration: 5000
    })
  }

  return Promise.reject(error.response || error.message)
}

export default request
