import request from '@/utils/request'
import {
  MainDataResponse,
  PdfResponse,
  SubmitFormPayload,
  SubmitOrderPayload,
  TidesResponse
} from '@/interfaces/api.interface'
import { LanguageCodesEnum } from '@/interfaces/languages.interface'
import { WeatherInterface } from '@/interfaces/weather.interface'
import { useHelperStore } from '@/pinia/helper.store'

const api = {
  initialisePdfGeneration(invitationId: string, langCode: LanguageCodesEnum): Promise<PdfResponse> {
    return request.get(`/wb/${invitationId}/pdf/?lang_code=${langCode}`)
  },
  getPdfStatus(
    invitationId: string,
    langCode: LanguageCodesEnum,
    taskId: string
  ): Promise<PdfResponse> {
    return request.get(`/wb/${invitationId}/pdf/`, {
      params: { lang_code: langCode, in_progress: true, task_id: taskId }
    })
  },
  checkGuideAvailability(invitationId: string): Promise<{ reload_required: boolean }> {
    if (invitationId) {
      return request.get(`/wb/${invitationId}/check_guide_availability/`, {
        timeout: 10000
      })
    }
  },
  getWeather(invitationId: string, language: LanguageCodesEnum): Promise<WeatherInterface> {
    const data = new FormData()
    const input = document.getElementsByName('csrfmiddlewaretoken') as NodeListOf<HTMLInputElement>

    if (!input) {
      throw new Error('CSRF not found.')
    }

    data.append('language', language)

    return request.post(`/wb/weather/${invitationId}/`, data, {
      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': input[0].value
      }
    })
  },
  getTides(invitationId: string, params?: { lat?: string; lon?: string }): Promise<TidesResponse> {
    return request.get(`/wb/tide/${invitationId}/`, { params })
  },
  getGuide(invitationId?: string): Promise<MainDataResponse> {
    const helperStore = useHelperStore()

    return request.get(`/wb/guide/${invitationId || helperStore.guideToken}/`)
  },
  getGooglePlace(
    topicId: number,
    language: LanguageCodesEnum
  ): Promise<Partial<google.maps.Place>> {
    return request.get(`/wb/google-place/${topicId}/`, { params: { language } })
  },
  getAllFonts(): Promise<{ name: string; assets: string[] }[]> {
    return request.get('/wb/fonts/')
  },
  submitCheckIn(topicId: number, payload: SubmitFormPayload): Promise<void> {
    const helperStore = useHelperStore()

    return request.post(`/wb/rating/${helperStore.guideToken}/${topicId}/`, payload)
  },
  submitFeedback(feedback: boolean) {
    const helperStore = useHelperStore()

    return request.post(`/wb/feedback/${helperStore.guideToken}/`, { feedback })
  },
  submitOrder(topicId: number, payload: SubmitOrderPayload) {
    const helperStore = useHelperStore()

    return request.post(`/wb/order/${helperStore.guideToken}/${topicId}/`, payload)
  }
}

export default api
