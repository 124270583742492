import throttle from 'lodash/throttle'

const pStart = { x: 0, y: 0 }
const pCurrent = { x: 0, y: 0 }

function onTouchStart(e: TouchEvent) {
  const touch = e.targetTouches[0]
  pStart.x = touch.screenX
  pStart.y = touch.screenY
}

function triggerReload() {
  const loadingEl = document.querySelector('.loading-container')
  loadingEl.classList.add('visible')

  setTimeout(() => {
    location.reload()
  }, 1500)
}

function onTouchMove(e) {
  // ignore pull-to-refresh for map
  if (window.location.href.includes('/map')) {
    return
  }

  const touch = e.changedTouches[0]
  pCurrent.x = touch.screenX
  pCurrent.y = touch.screenY
  const changeY = pStart.y < pCurrent.y ? Math.abs(pStart.y - pCurrent.y) : 0

  const scrollableParent = getScrollableParent(e.target)

  if (scrollableParent.scrollTop === 0 && changeY > 150) {
    triggerReload()
  }
}

function isScrollable(el): boolean {
  const hasScrollableContent = el.scrollHeight > el.clientHeight

  const overflowYStyle = window.getComputedStyle(el).overflowY
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

  return hasScrollableContent && !isOverflowHidden
}

function getScrollableParent(el: Element): Element {
  return !el || el === document.body
    ? document.body
    : isScrollable(el)
    ? el
    : getScrollableParent(el.parentElement)
}

export function initPullToRefresh() {
  document.addEventListener('touchstart', onTouchStart, false)
  document.addEventListener('touchmove', throttle(onTouchMove, 500), false)
}
