import { DESKTOP_BREAKPOINT, PlausibleEvents, PlausibleProperties } from '@/utils/consts'
import router from '@/router'
import RouteNamesEnum from '@/router/route-names'
import { Category, Subcategory } from '@/interfaces/guide.interface'
import { plausible } from '@/main'
import AvailableLanguages from '@/interfaces/translation.interface'
import { LanguageCodesEnum } from '@/interfaces/languages.interface'
import { useHelperStore } from '@/pinia/helper.store'
import { useMainStore } from '@/pinia/main.store'
import reject from 'lodash/reject'
import throttle from 'lodash/throttle'
import { ref } from 'vue'

export function sendPlausibleEvent(
  eventName: PlausibleEvents,
  props?: Record<string, string | number | boolean>
) {
  const helperStore = useHelperStore()
  const mainStore = useMainStore()

  // ignore if in iframe
  if (isPreviewMode()) {
    return
  }

  plausible.trackEvent(eventName, {
    props: {
      ...props,
      [PlausibleProperties.DESTINATION_ID]: mainStore.propertyInfo.id,
      [PlausibleProperties.ACCOUNT_ID]: mainStore.propertyInfo.account_id,
      [PlausibleProperties.GUIDE_TOKEN]: helperStore.guideToken
    }
  })
}

// iframe means we are in the preview mode
export function isPreviewMode() {
  return window.self !== window.top
}

export const isMobile = ref(window.innerWidth <= DESKTOP_BREAKPOINT)

window.addEventListener(
  'resize',
  throttle(() => {
    isMobile.value = window.innerWidth <= DESKTOP_BREAKPOINT
  }, 500)
)

// need to load and cache all images initially
export function preloadImages(imageUrls: string[]) {
  imageUrls.forEach((imageUrl) => {
    const img = new Image()

    img.src = imageUrl
  })
}

function getHref(link: HTMLAnchorElement): string {
  return link.attributes.getNamedItem('href')?.value || ''
}

function getRouteNameBySubcategoryId(subcategoryId: number): RouteNamesEnum {
  const mainStore = useMainStore()
  const { guideCategories, infoCategories } = mainStore
  const getSubcategories = (categories: Category[]) => {
    return categories.reduce((result, category) => {
      return [...result, ...category.subcategories]
    }, [] as Subcategory[])
  }
  const infoSubcategories = getSubcategories(infoCategories)

  if (infoSubcategories.find((s) => s.id === subcategoryId)) {
    return RouteNamesEnum.INFO
  }

  const guideSubcategories = getSubcategories(guideCategories)

  if (guideSubcategories.find((s) => s.id === subcategoryId)) {
    return RouteNamesEnum.GUIDE
  }

  console.error('Subcategory not found')
  return null
}

export function transformLinksWithin(links: HTMLAnchorElement[]) {
  const regex = /^\d+(\/?#\d+)?$/ // subcategoryId#topicId (topicId is optional)

  const isInternalLink = (link: HTMLAnchorElement) => {
    const href = getHref(link)

    if (!href) {
      return false
    }

    return (
      regex.test(href) || href.includes('?categoryId=') || href === '#map' || href === '#search'
    ) // Examples: 15623#2122, ?categoryId=5111, #map, #search
  }

  const getCategoryId = (link: HTMLAnchorElement) => {
    return getHref(link).split('categoryId=')[1] // retrieve the id part
  }

  const addInternalLinkListener = (link: HTMLAnchorElement) => {
    if (!link) {
      return
    }

    link.onclick = async (e) => {
      const helperStore = useHelperStore()
      e.preventDefault()

      const currentHref = getHref(link)

      if (currentHref === '#map') {
        await router.push({ name: RouteNamesEnum.MAP })
        return
      }

      if (currentHref === '#search') {
        await router.push({ name: RouteNamesEnum.SEARCH })
        return
      }

      const categoryId = getCategoryId(link)

      // Category nav
      if (getHref(link).includes('?categoryId=') && categoryId) {
        const mainStore = useMainStore()
        const routeName = mainStore.infoCategories.find((c) => c.id === +categoryId)
          ? RouteNamesEnum.INFO
          : RouteNamesEnum.GUIDE
        await router.push({
          name: routeName,
          query: { categoryId }
        })

        return
      }

      // Subcategory nav
      const href = getHref(link).split('#')
      const [subcategoryId, topicId] = href || []

      if (!subcategoryId) {
        return
      }

      helperStore.shouldStashBeChecked = false
      const routeName = getRouteNameBySubcategoryId(+subcategoryId)

      if (routeName) {
        await router.push({
          name: routeName,
          params: { subcategoryId },
          hash: topicId ? '#' + topicId : ''
        })
      }
    }
  }

  links.filter(isInternalLink).forEach(addInternalLinkListener)
  // opposite to filter, send plausible event for non-internal link clicks
  reject(links, isInternalLink).forEach((a) => {
    a.onclick = () => {
      sendPlausibleEvent(PlausibleEvents.EXTERNAL_LINK_CLICK, {
        [PlausibleProperties.LINK]: a.href
      })
    }
  })
}

export function generateFileFromResponse(
  source,
  name,
  type = 'application/pdf',
  extension = 'pdf'
) {
  const binaryString = window.atob(source)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  const url = window.URL.createObjectURL(new Blob([bytes], { type }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${name}.${extension}`)
  document.body.appendChild(link)
  link.click()
}

export function showDevMessage(type: 'info' | 'error' | 'success' = 'info', message: string) {
  switch (type) {
    case 'info':
      console.info(message)
      break
    case 'error':
      console.error(message)
      break
    case 'success':
    default:
      console.log(message)
  }

  if (window.location.origin.includes('develop.touchstay.com')) {
    // ElNotification({
    //   type,
    //   message,
    //   duration: 0
    // })
  }
}

export function automaticallyResizeFont(selector: string) {
  const el = document.querySelector(selector) as HTMLDivElement

  if (!el) {
    return
  }

  const widthBefore = el.clientWidth
  el.classList.add('nowrap')

  while (el.scrollWidth > widthBefore) {
    const style = window.getComputedStyle(el)

    if (style.fontSize.includes('px')) {
      const size = +style.fontSize.split('px')[0]
      el.style.fontSize = size - 2 + 'px'
    }
  }

  el.classList.remove('nowrap')
}

export function getCurrentLanguageBasedOnBrowser(languages: AvailableLanguages): LanguageCodesEnum {
  const browserLanguageCode =
    navigator.language === 'id' ? LanguageCodesEnum.IND : navigator.language.substring(0, 2)
  let currentLanguageCode = browserLanguageCode
  const norwegianCodes = ['nb', 'no', 'nn'] // Norwegian dialects fall back to 'no'
  const doesBrowserLanguageExist = !!languages.translations[currentLanguageCode]

  if (norwegianCodes.includes(browserLanguageCode)) {
    currentLanguageCode = LanguageCodesEnum.NO
  }

  return doesBrowserLanguageExist
    ? (currentLanguageCode as LanguageCodesEnum)
    : languages.guide_fallback_language
}
