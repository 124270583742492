import { acceptHMRUpdate, defineStore } from 'pinia'
import { MapData } from '@/interfaces/map.interface'
import { computed, ref } from 'vue'
import { LanguageCodesEnum } from '@/interfaces/languages.interface'
import AvailableLanguages from '@/interfaces/translation.interface'
import api from '@/api'
import { StyleData } from '@/interfaces/state.interface'
import { StyleResponse } from '@/interfaces/api.interface'
import { showDevMessage } from '@/utils/helpers'
import { FALLBACK_FONTS } from '@/utils/consts'

export const useHelperStore = defineStore('helper', () => {
  // map
  const mapData = ref<MapData>({} as MapData)
  const activeMapFilterType = ref('')
  // languages
  const currentLanguage = ref(LanguageCodesEnum.EN)
  const availableLanguages = ref<AvailableLanguages>({
    guide_fallback_language: LanguageCodesEnum.EN,
    translations: { en: {} },
    languages: { user_languages: [], master_languages: [] }
  })
  // helpers
  const shouldStashBeChecked = ref(true)
  const guideToken = ref('')
  const errorMessage = ref('')
  const isLoadingViewVisible = ref(true)
  const styleData = ref<StyleData>({} as StyleData)

  const textFont = computed(() => `${styleData.value.mainFont}, ${FALLBACK_FONTS}`)
  const headerFont = computed(() => `${styleData.value.alternateFont}, ${FALLBACK_FONTS}`)

  function i18n(key: string): string {
    const { translations, guide_fallback_language } = availableLanguages.value

    if (!translations || !key) {
      return ''
    }

    const lang = translations[currentLanguage.value]
    let textResult = lang?.[key]

    if (!textResult) {
      const fallbackLanguage = translations[guide_fallback_language]

      textResult = fallbackLanguage?.[key]
    }

    return textResult || ''
  }

  async function checkGuideAvailability() {
    const { reload_required } = await api.checkGuideAvailability(guideToken.value)

    if (reload_required) {
      window.location.reload()
    }
  }

  async function setStyleData(style: StyleResponse) {
    styleData.value = {
      mainFont: style.main_font.name,
      alternateFont: style.alternate_font.name,
      overlayColor: style.overlay_color,
      overlayTransparency: style.overlay_transparency,
      backgroundColor: style.background_color,
      accentColor: style.accent_color,
      logoStyle: style.logo_style,
      navigationColor: style.navigation_color,
      menuColor: style.menu_color,
      allowGuestFeedback: style.allow_guest_feedback
    }
    const getSources = (assets) => assets.map((url) => `url(${url})`).join(', ')
    const descriptors = {
      weight: 'normal',
      style: 'normal',
      display: 'swap'
    }

    if (style.main_font.assets.length) {
      const primaryFontFace = new FontFace(
        style.main_font.name,
        getSources(style.main_font.assets),
        descriptors
      )

      await primaryFontFace.load()

      document.fonts.add(primaryFontFace)
    }

    if (style.alternate_font.assets.length) {
      const secondaryFontFace = new FontFace(
        style.alternate_font.name,
        getSources(style.alternate_font.assets),
        descriptors
      )

      await secondaryFontFace.load()

      document.fonts.add(secondaryFontFace)
    }

    showDevMessage('success', 'Fonts loaded!')
  }

  return {
    mapData,
    activeMapFilterType,
    currentLanguage,
    availableLanguages,
    shouldStashBeChecked,
    guideToken,
    errorMessage,
    isLoadingViewVisible,
    styleData,
    textFont,
    headerFont,
    i18n,
    checkGuideAvailability,
    setStyleData
  }
})

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHelperStore, import.meta.hot))
}
