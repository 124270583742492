import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import router from '@/router'
import App from '@/App.vue'
import Plausible from 'plausible-tracker'
import { isPreviewMode } from '@/utils/helpers'

const app = createApp(App)
export const pinia = createPinia()

if (import.meta.env.VITE_TARGET_ENV && import.meta.env.PROD) {
  Sentry.init({
    app,
    release: '1.1.16',
    dsn: 'https://c4eb291f733a401eb201fa8e4d76a423@o1361933.ingest.sentry.io/6654040',
    environment: import.meta.env.VITE_TARGET_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'Non-Error exception captured with keys: config, data, headers, request, status',
      'Extension context invalidated.',
      'Illegal invocation',
      'timeout of 10000ms exceeded',
      'Share canceled',
      'Network Error',
      'Abort due to cancellation of share.',
      'Load failed',
      'Importing a module script failed.',
      'The operation is insecure.'
    ],
    tracesSampleRate: 0.1,
    logErrors: true
  })
}

export const plausible = Plausible({
  domain: import.meta.env.VITE_DOMAIN,
  apiHost: import.meta.env.VITE_PLAUSIBLE_API_HOST,
  trackLocalhost: false
})

// not an iframe
if (!isPreviewMode()) {
  plausible.enableAutoPageviews()
}

app.use(pinia)
app.use(router)

app.mount('#app')
