<template>
  <div class="font-view">
    <h1>Fonts Test View</h1>
    <hr />

    <div v-for="font in data.fonts" :key="font.name" :style="{ fontFamily: font.name }">
      <h2>{{ font.name }}</h2>
      <h2 style="-webkit-font-smoothing: antialiased">{{ font.name }} (AA)</h2>
      <h2 style="font-weight: bold">{{ font.name }} (Bold)</h2>
      <h2 style="font-weight: bold; -webkit-font-smoothing: antialiased">
        {{ font.name }} (Bold & AA)
      </h2>

      <p>
        Whereas disregard and contempt for human rights have resulted in existence of a certain
        country
      </p>

      <p style="-webkit-font-smoothing: antialiased">
        Whereas disregard and contempt for human rights have resulted in existence of a certain
        country
      </p>

      <p style="font-weight: bold">
        Whereas disregard and contempt for human rights have resulted in existence of a certain
        country (Bold)
      </p>

      <p style="font-weight: bold; -webkit-font-smoothing: antialiased">
        Whereas disregard and contempt for human rights have resulted in existence of a certain
        country (Bold & AA)
      </p>
      <hr />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import api from '@/api'
import { useHelperStore } from '@/pinia/helper.store'

const helperStore = useHelperStore()
const data = reactive({ fonts: [] })

onMounted(async () => {
  const getSources = (assets) => assets.map((url) => `url(${url})`).join(', ')
  const descriptors = {
    weight: 'normal',
    style: 'normal',
    display: 'swap'
  }

  data.fonts = await api.getAllFonts()
  helperStore.isLoadingViewVisible = false
  data.fonts.forEach(async (font) => {
    const fontFace = new FontFace(font.name, getSources(font.assets), descriptors)

    await fontFace.load()

    document.fonts.add(fontFace)
  })
})
</script>

<style lang="scss" scoped>
.font-view {
  padding: 20px;
  overflow: auto;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: initial;

  h2,
  p {
    margin: 0;
    padding: 5px 0;
    font-weight: normal;
  }

  h2 {
    font-size: 1.25rem;
  }
}
</style>
