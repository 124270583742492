enum RouteNamesEnum {
  ERROR = 'Error',
  HOME = 'Home',
  INFO = 'Info',
  GUIDE = 'Guide',
  MAP = 'Map',
  SEARCH = 'Search'
}

export default RouteNamesEnum
